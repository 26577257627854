import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`When providing an email address as an input to the minFraud services, you can
provide it either as plain text or as an MD5 hash.`}</p>
    <p>{`If you provide the email as an MD5 hash, it’s important that you normalize it
before generating the hash. Otherwise minor, inconsequential differences could
cause minFraud to consider it a different address.`}</p>
    <p>{`Our `}
      <a {...{
        "href": "/minfraud/api-documentation/#client-apis",
        "parentName": "p"
      }}>{`client APIs`}</a>
      {` do this for you if
you enable sending the MD5 hash. This is the recommended way to do this.`}</p>
    <div {...{
      "id": "toc-normalizing-email-addresses"
    }}><h2 {...{
        "id": "normalizing-email-addresses",
        "parentName": "div"
      }}>{`Normalizing email addresses`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`If you are not able to use our client APIs, you can normalize an email address
yourself. Below are the steps to take to do this.`}</p>
      <ol {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ol"
        }}>{`Trim whitespace from both ends of the address.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Lowercase the address.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Find the local part of the email (before the `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`@`}</inlineCode>
          {`) and the domain (after the
`}
          <inlineCode {...{
            "parentName": "li"
          }}>{`@`}</inlineCode>
          {`).`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Apply NFC normalization to the email local part.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Trim whitespace from the beginning of the domain.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`If the domain ends with any number of periods, trim them off.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Convert international domain names (IDNs) to ASCII. For example, you can do
this in Java using
`}
          <a {...{
            "href": "https://docs.oracle.com/javase/8/docs/api/java/net/IDN.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`java.net.IDN`}</a>
          {`.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`If the domain ends with a repetition of `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`.com`}</inlineCode>
          {` (`}
          <inlineCode {...{
            "parentName": "li"
          }}>{`.com.com`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`.com.com.com`}</inlineCode>
          {`,
etc.), replace with a single `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`.com`}</inlineCode>
          {`.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`If the domain is `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`gmail.com`}</inlineCode>
          {` with any leading digits, it is replaced with
`}
          <inlineCode {...{
            "parentName": "li"
          }}>{`gmail.com`}</inlineCode>
          {` (i.e., `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`123gmail.com`}</inlineCode>
          {` is replaced with `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`gmail.com`}</inlineCode>
          {`).`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Check for typos in the TLD and correct them. For a complete list of typos we
correct, consult the
`}
          <a {...{
            "href": "#examples",
            "parentName": "li"
          }}>{`normalization code in one of our client APIs`}</a>
          {` below.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Check for typos in the domain name and correct them. For a complete list of
typos we correct, consult the
`}
          <a {...{
            "href": "#examples",
            "parentName": "li"
          }}>{`normalization code in one of our client APIs`}</a>
          {` below.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`If the domain is `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`fastmail.com`}</inlineCode>
          {` or any of the
`}
          <a {...{
            "href": "https://www.fastmail.com/about/ourdomains/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`fastmail domains`}</a>
          {`, replace the
email local part with the subdomain (i.e., `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`alias@user.fastmail.com`}</inlineCode>
          {` is
replaced with `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`user@fastmail.com`}</inlineCode>
          {`).`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`If the domain has an equivalent, such as `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`googlemail.com`}</inlineCode>
          {` to `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`gmail.com`}</inlineCode>
          {`,
replace it with the equivalent. For the list of equivalent domains we use,
consult the `}
          <a {...{
            "href": "#examples",
            "parentName": "li"
          }}>{`normalization code in one of our client APIs`}</a>
          {` below.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Remove alias parts from the local part. For addresses at the `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`yahoo.com`}</inlineCode>
          {`
domain, or other domains affiliated with Yahoo, this is everything after and
including the first `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`-`}</inlineCode>
          {` character, if present. For addresses with all other
domains, this is everything after and including the first `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`+`}</inlineCode>
          {` character, if
present.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Remove periods from `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`gmail.com`}</inlineCode>
          {` local parts.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Put the local part and the domain back together to form the normalized email
address.`}</li>


        <li {...{
          "parentName": "ol"
        }}>{`Calculate the MD5 hash.`}</li>

      </ol></div>
    <div {...{
      "id": "toc-examples"
    }}><h2 {...{
        "id": "examples",
        "parentName": "div"
      }}>{`Examples`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`You can review the code in our client APIs see how to normalize an email address
in various languages.`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/minfraud-api-dotnet/blob/main/MaxMind.MinFraud/Request/Email.cs#L395",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`.NET (C#)`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/minfraud-api-java/blob/main/src/main/java/com/maxmind/minfraud/request/Email.java#L414",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Java`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/minfraud-api-node/blob/main/src/request/email.ts#L313",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`JavaScript`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/minfraud-api-php/blob/main/src/MinFraud/Util.php#L284",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`PHP`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/minfraud-api-python/blob/main/minfraud/request.py#L357",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Python`}</a></li>


        <li {...{
          "parentName": "ul"
        }}><a {...{
            "href": "https://github.com/maxmind/minfraud-api-ruby/blob/main/lib/minfraud/components/email.rb#L84",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "li"
          }}>{`Ruby`}</a></li>

      </ul></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      